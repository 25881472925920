<template lang='pug'>
.selector-template
  .max-w-xl.mx-auto.mb-12
    baseH1Pages.pt-8.pb-4
      .text-4xl Selecciona la plantilla
    p.text-center.text-sm Las siguientes plantillas para curriculum han sido diseñadas especialmente para conseguir la mejor impresion en tu busqueda de empleo
  .box-templates(ref="rootView")
    div.w-full
      XyzTransition(
        appear,
        xyz="fade origin-right right-1 ease-out-back duration-10 delay-2"
      )
        Carousel(:settings="settings", v-if="enableSlider")
          template(#slides)
            Slide(
              v-for="template in listTemplates",
              :key="template.template_id_num"
            )
              .carousel__item
                .box-colors
                  .color(v-for="(colors, index) in template.option_colors" :key="index")
                    span(:style="`background-color: ${colors.color1}`")
                    //- span(:style="`background-color: ${colors.color2}`")
                .curriculum-box
                  .content-cv
                    baseButton.button-select(
                      @click="selectTemplate(template.template_id_num)"
                    ) Seleccionar
                  img(:src="`/theme/resumes/${template.template_id_num}.png`")
          template(#addons)
            Navigation
</template>
<script>
import { useRouter } from "vue-router";

import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { ref, onMounted, onUnmounted, computed, inject } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  data: () => ({}),
  setup() {
    const router = useRouter();
    const store = useStore();
    const settings = ref();
    const enableSlider = ref(false);
    const $ObserserFormSync = inject("$ObserserFormSync");

    const listTemplates = computed(() => {
      return store.getters["TemplatesStore/getTemplates"];
    });

    settings.value = {
      wrapAround: true,
      itemsToShow: 1,
      snapAlign: "center",
    };
    const rootView = ref(null);

    onMounted(() => {
      resize(rootView);
      window.addEventListener("resize", resize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", resize);
    });

    const resize = () => {
      let box = rootView.value;
      let altoMaximo = box.offsetHeight - 60;
      let ratio = Math.floor(altoMaximo * 0.2645833333) / 297;
      let ratioImage = 210 * ratio;
      let largoDiv = Math.floor(box.offsetWidth * 0.2645833333); //mm
      let anchosMaximo = largoDiv / ratioImage;
      console.log({ altoMaximo });
      console.log({ ratio });
      console.log({ ratioImage });
      console.log({ largo: largoDiv });
      console.log({ anchosMaximo: anchosMaximo });
      let slidesXview = +anchosMaximo.toFixed(2);
      if (slidesXview <= 1.2) slidesXview = 1;
      settings.value.itemsToShow = slidesXview;
      enableSlider.value = true;
    };

    const selectTemplate = async (template_id) => {
      await store.dispatch(
        "DataCvBuilderStore/changeTemplateSelected",
        template_id
      );
      if (
        store.getters["UserStore/getIsLogged"] &&
        store.getters["DataCvBuilderStore/getCurriculumId"]
      ) {
        $ObserserFormSync();
        router.push({
          name: "builder/creator/with-id",
          params: {
            curriculum_id: store.getters["DataCvBuilderStore/getCurriculumId"],
          },
        });
      } else {
        router.push({ name: "builder/creator" });
      }
    };

    return {
      listTemplates,
      settings,
      rootView,
      enableSlider,
      selectTemplate,
    };
  },
};
</script>
<style lang="scss" scoped>
.box-colors {
  @apply flex items-center mb-2 justify-end w-full;
  .color {
    @apply h-3 w-3 rounded-full overflow-hidden flex;
    span {
      @apply h-full w-full block m-0 p-0;
    }

    & + .color {
      @apply ml-1;
    }
  }
}
.selector-template {
  height: calc(100vh - 57px);
  max-height: calc(100vh - 57px);
  @apply flex flex-col flex-grow flex-shrink w-full;
}

.box-templates {
  @apply flex items-start h-full;
}

.carousel {
  @apply h-full;
  .carousel__viewport {
    @apply h-full overflow-visible;
  }
}

.carousel__item .curriculum-box {
  @apply shadow-2xl overflow-hidden rounded-xl relative w-full;
  img {
    @apply w-full;
  }
  .content-cv {
    @apply absolute w-full h-full z-10 flex;

    .button-select {
      @apply bg-blue-600 text-white self-center mx-auto hidden font-medium transition-colors shadow-xl;
      &:hover {
        @apply opacity-100 bg-blue-700;
      }
    }
  }
}
.carousel__item {
  @apply h-full w-full flex justify-center items-start flex-col;
}

.carousel__slide {
  @apply flex flex-col items-start pt-0 px-6 pb-3;
}

.carousel__slide > .carousel__item {
  @apply opacity-100 transition-transform transform scale-100;
  .curriculum-box {
  }
}

.carousel__slide--active > .carousel__item {
  @apply scale-105;

  .curriculum-box .content-cv .button-select {
    @apply block;
  }
}

.carousel :deep(.carousel__viewport) {
  height: 100% !important;
  overflow: visible !important;
}
.carousel :deep(.carousel__viewport .carousel__track) {
  height: 100% !important;
}

.carousel :deep(.carousel__prev) {
  top: 50%;
  left: 2rem;
  transform: translate(-50%, -70%);
  @apply bg-blue-400 transition-colors shadow-xl;
  &:hover {
    @apply bg-blue-600;
  }
}

.carousel :deep(.carousel__next) {
  top: 50%;
  right: 2rem;
  transform: translate(50%, -70%);
  @apply bg-blue-400 transition-colors shadow-xl;
  &:hover {
    @apply bg-blue-600;
  }
}
</style>